import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  siteName?: string
}

export const BannerCTA = memo(function BannerCTA({ items, siteName }: Props) {
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      {siteName ? <SiteName>{siteName}</SiteName> : null}

      <Wrapper row space="between" wrap>
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  padding: 5.625rem 0 10.875rem;

  @media (max-width: 1023px) {
    padding: 2.5rem 2rem 2.25rem;
  }
`

const SiteName = styled.div`
  max-width: 28.125rem;
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.75rem;
  margin: 0 auto 3.75rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    max-width: none;
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 1.9375rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin-right: -0.125rem;

  @media (max-width: 1023px) {
    display: block;
    margin-right: 0;
  }
`
